import React, { useEffect, useRef, useState } from 'react';

import Head from 'next/head';
import script from 'next/script';
import { useRecoilValue } from 'recoil';
import storeConfigAtom from '../store-config/store-config.atom';

export const GOOGLE_CONVERSION = 'GOOGLE_CONVERSION';
export const GOOGLE_CONVERSION_BEGIN_CHECKOUT = 'GOOGLE_CONVERSION_BEGIN_CHECKOUT';
export const REMARKETING_HOME = 'REMARKETING_HOME';
export const REMARKETING_PRODUCT = 'REMARKETING_PRODUCT';
export const REMARKETING_CART = 'REMARKETING_CART';
export const REMARKETING_SEARCH = 'REMARKETING_SEARCH';

const ReMaketing: React.FC<{
    data?: any;
    gtagType: string;
}> = ({ data, gtagType }) => {
    const { marketingConfig } = useRecoilValue(storeConfigAtom.store);
    const firstTime = useRef(false);

    const meta_gtm = marketingConfig?.googleConversionTrackings || [];
    if (gtagType === GOOGLE_CONVERSION && !firstTime.current) {
        // @ts-ignore
        const price = data?.total;
        // @ts-ignore
        const order_id = data?.id;
        if (!!meta_gtm?.length && price != null && price != undefined && order_id) {
            firstTime.current = true;
            return (
                <Head>
                    <script id={GOOGLE_CONVERSION}>
                        {meta_gtm?.map((meta_gtm_item) => {
                            return `
                    gtag('event', 'conversion', {
                        'send_to': '${meta_gtm_item['value']}/${meta_gtm_item['label']}',
                        'value': '${price}',
                        'currency': 'USD',
                        'transaction_id': '${order_id}'
                        });    
                `;
                        })}
                    </script>
                </Head>
            );
        }
    }

    if (gtagType === REMARKETING_HOME && !firstTime.current) {
        if (!!meta_gtm?.length) {
            firstTime.current = true;
            return (
                <Head>
                    <script id={REMARKETING_HOME}>
                        {meta_gtm?.map((meta_gtm_item) => {
                            return `
                  gtag('event', 'home', {
                    'send_to': '${meta_gtm_item['value']}'
                  });
                `;
                        })}
                    </script>
                </Head>
            );
        }
    }

    if (gtagType === REMARKETING_PRODUCT && !firstTime.current) {
        // @ts-ignore
        const price = data?.price;
        // @ts-ignore
        const sku = data?.sku;
        if (!!meta_gtm?.length && price && sku) {
            firstTime.current = true;

            return (
                <Head>
                    <script id={REMARKETING_PRODUCT}>
                        {meta_gtm?.map((meta_gtm_item) => {
                            return `
              gtag('event', 'view_item', {
                  'send_to': '${meta_gtm_item['value']}',
                  'value': '${price}',
                  'items': [{
                      'id': '${sku}',
                      'google_business_vertical': 'retail'
                  }]
              });
            `;
                        })}
                    </script>
                </Head>
            );
        }
    }

    if (gtagType === REMARKETING_CART && !firstTime.current) {
        // @ts-ignore
        const grand_total =
            !!data?.cart &&
            !!data?.cart.prices &&
            !!data?.cart.prices.grand_total &&
            !!data?.cart.prices.grand_total.value &&
            data?.cart.prices.grand_total.value;
        // @ts-ignore
        const items = !!data?.cart && !!data?.cart.items && data?.cart.items;
        // @ts-ignore
        const product_ids = [];
        // @ts-ignore
        for (var product in items) {
            product_ids.push({
                id: items[product]['product']['sku'],
                google_business_vertical: 'retail'
            });
        }

        if (!!meta_gtm?.length && grand_total && product_ids) {
            const products = JSON.stringify(product_ids);
            firstTime.current = true;
            return (
                <Head>
                    <script id={REMARKETING_CART}>
                        {meta_gtm?.map((meta_gtm_item) => {
                            return `                               
                                gtag('event', 'add_to_cart', {
                                'send_to': '${meta_gtm_item['value']}',
                                'value': '${grand_total}',
                                'items': ${products},
                                });
                            `;
                        })}
                    </script>
                </Head>
            );
        }
    }

    if (gtagType === REMARKETING_SEARCH && !firstTime.current) {
        if (!!meta_gtm?.length) {
            firstTime.current = true;
            return (
                <Head>
                    <script id={REMARKETING_SEARCH}>
                        {meta_gtm?.map((meta_gtm_item) => {
                            return `                               
                                gtag('event', 'view_search_results', {
                                'send_to': '${meta_gtm_item['value']}'
                                 'value': '${data?.keyword}',
                                });
                            `;
                        })}
                    </script>
                </Head>
            );
        }
    }

    return null;
};
export default ReMaketing;
